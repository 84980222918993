<template>
    <div class="main">
        <div v-for="(item) in data" class="content">
            <a :href="item.url">
                <el-button size="large" round type="danger">{{ item.name }}</el-button>
            </a>
        </div>
        <div class="content">
            <a href="ts3server://kougami.cn">
                <el-button size="large" round type="primary">TeamSpeak语音服务器</el-button>
            </a>
        </div>
        <vue-particles
            color="#409EFF"
            :particleOpacity="0.7"
            :particlesNumber="60"
            shapeType="circle"
            :particleSize="6"
            linesColor="#409EFF"
            :linesWidth="1"
            :lineLinked="true"
            :lineOpacity="0.4"
            :linesDistance="150"
            :moveSpeed="3"
            :hoverEffect="true"
            hoverMode="repulse"
            :clickEffect="false"
            clickMode="push">
        </vue-particles>
    </div>
</template>

<script>
    export default {
        name: "MainPage",
        methods: {
        },
        data() {
            return {
                data: [
                    {
                        name: "博客(Halo)",
                        url: "https://blog.kougami.cn",
                        image: "./src/assets/blog.png"
                    },
                    {
                        name: "网盘(AList)",
                        url: "https://alist.kougami.cn"
                    },
                    {
                        name: "网盘(AList)(IPv6)",
                        url: "http://alistv6.kougami.cn"
                    },
                    {
                        name: "网盘(Hamster Hub)",
                        url: "https://pan.kougami.cn"
                    },
                    {
                        name: "网盘(Hamster Hub)(IPv6)",
                        url: "http://panv6.kougami.cn"
                    },
                    {
                        name: "Docker(Portainer)",
                        url: "https://docker.kougami.cn"
                    },
                    {
                        name: "Nginx",
                        url: "https://nginx.kougami.cn"
                    },
                    {
                        name: "Frp",
                        url: "https://frp.kougami.cn"
                    },
                    {
                        name: "ChatGPT(oaifree)",
                        url: "https://chat.kougami.cn"
                    },
                    {
                        name: "Pi Dashboard",
                        url: "https://pi.kougami.cn"
                    },
                    {
                        name: "Pi Docker(Portainer)",
                        url: "https://docker.pi.kougami.cn"
                    },
                    {
                        name: "青龙面板",
                        url: "https://qinglong.kougami.cn"
                    },
                    {
                        name: "TS3 Manager",
                        url: "https://ts.kougami.cn"
                    },
                    {
                        name: "图床",
                        url: "https://img.kougami.cn"
                    },
                    {
                        name: "笔记",
                        url: "https://memo.kougami.cn"
                    },

                ]
            }
        },
        created() {
            
        }
    }
</script>
    
<style>
    #particles-js{
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
    }
    .particles-js-canvas-el {
        width: 100vw !important;
        height: 100vh !important;
        position: fixed;
        top: 0;
        left: 0;
    }

    body, html {
        margin: 0;
        padding: 0;
        overflow: hidden; /* 隐藏全局滚动条 */
    }
    .main {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); /* 自动填充列宽度至少 200px */
        grid-template-rows: repeat(auto-fit, minmax(100px, 1fr));
        grid-auto-flow: column;
        gap: 15px; /* 项目之间的间距 */
        height: 100vh;
        width: 100vw;
        overflow: hidden; /* 禁用滚动条 */
        padding: 20px;
        box-sizing: border-box;
        position: absolute; /* 绝对定位 */
        top: 50%; /* 垂直居中 */
        left: 50%; /* 水平居中 */
        transform: translate(-50%, -50%); /* 通过变换居中 */
        z-index: 999 !important;
    }
    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999 !important;
    }
    a {
        text-decoration:none;
        color: white;
    }
</style>