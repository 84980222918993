import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueParticles from 'vue-particles'  

import "@/assets/css/global.css"
Vue.config.productionTip = false
Vue.use(ElementUI, {size: 'small'})
Vue.use(VueParticles)

new Vue({
  el: '#app',
  render: h => h(App)
});