<template>
  <div id="app">
    <MainPage />
  </div>
</template>

<script>
import MainPage from './pages/MainPage.vue';
  export default {
    components: { MainPage }
}
</script>